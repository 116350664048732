// fgfgf
@import "./_metronic/_assets/new/sass/style.scss";

// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";
@import "./_metronic/_assets/new/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}
.kt-header-menu-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

.draggable-table {
  position: absolute;
  width: 92%;
  border-collapse: collapse;
  background: white;  
  -webkit-box-shadow: 0px 0px 10px 8px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 10px 8px rgba(0, 0, 0, 0.1);  
  
  .draggable-table__drag {
      font-size: .95em;
      font-weight: lighter;
      text-transform: capitalize;
      position: absolute;
      width: 100%;
      text-indent: 50px;    
      border: 1px solid #f1f1f1;
      z-index: 10;
      cursor: grabbing;
      -webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);
              box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05); 
    
      opacity: 1;
  }  
  
  thead {
    
    th {
      padding: 10px;
      text-align: center;
    }
  }
  
  tbody {
    
    tr {
      cursor: grabbing;
      
      td {
        border-top: 1px solid whitesmoke;
        text-align: center;
        padding: 20px;
      }
    }
    
    tr:nth-child(even) {
        background-color: #f7f7f7;
    }

    tr:nth-child(odd) {
        background-color: #ffffff;
    }    
    
    tr.is-dragging {
        background: #5d78ff;

        td {
            color: #6f42c1;
            text-align: center;
        }
    }    
  }
}
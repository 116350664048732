//
// Scrolltop
//

// Base
.kt-scrolltop {
  display: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 4%;
  right: 60px;
  cursor: pointer;
  z-index: 100;
  background: kt-brand-color();
  box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.2);
  opacity: 0;
  @include kt-transition();

  @include kt-rounded {
    border-radius: $kt-border-radius;
  }

  svg {
    height: 20px;
    width: 20px;
  }

  > i {
    font-size: 1.3rem;
    color: kt-brand-color(inverse);
  }

  @include kt-svg-icon-color(kt-brand-color(inverse));

  &:hover {
    > i {
      color: kt-brand-color(inverse);
    }
  }

  .kt-scrolltop--on & {
    opacity: 0.3;
    animation: kt-scrolltop-show-animation 0.4s ease-out 1;
    display: flex;

    &:hover {
      @include kt-transition();
      opacity: 1;
    }
  }

  @include kt-tablet-and-mobile {
    bottom: 30px;
    right: 15px;
  }
}

// Animations
@keyframes kt-scrolltop-show-animation {
  from {
    margin-bottom: -15px;
  }
  to {
    margin-bottom: 0;
  }
}
